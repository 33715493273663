import { defineComponent } from 'vue';
import CustomerDetailModal from '@/components/Customers/CustomerDetailModal';
import useCustomers from '@/compositions/customers';
import useReservations from '@/compositions/reservations';
import { convertTimestamp, DAY_MILLISECONDS, midnightOfDate } from '@/classes/timestamps';
import { generateCsvDownload, getCustomerCSV } from '@/classes/csv';
import MakeReservationButton from '@/components/Reservation/MakeReservationButton';
import EditReservationButton from '@/components/Reservation/EditReservationButton';
export default defineComponent({
    name: 'ReservationOverviewTable',
    components: {
        CustomerDetailModal,
        MakeReservationButton,
        EditReservationButton
    },
    setup(_props) {
        return { ...useCustomers(), ...useReservations(true) };
    },
    data: () => ({
        editReservationId: null
    }),
    computed: {
        labels() {
            const labels = [];
            for (let index = 0; index <= this.reservationDaySpan; ++index) {
                labels.push(this.getDateAfterMinDate(index - 1) + ' tot ' + this.getDateAfterMinDate(index));
            }
            return labels;
        },
        renderableReservations() {
            return Object.entries(this.reservations).map(([id, reservation]) => {
                var _a, _b, _c, _d, _e, _f;
                let customername = (_b = (_a = this.customers[reservation.customerid]) === null || _a === void 0 ? void 0 : _a.firstname) !== null && _b !== void 0 ? _b : '';
                customername += (_d = ' ' + ((_c = this.customers[reservation.customerid]) === null || _c === void 0 ? void 0 : _c.lastname)) !== null && _d !== void 0 ? _d : '';
                const licenseplate = (_e = reservation.licenseplate) !== null && _e !== void 0 ? _e : '';
                const customerid = reservation.customerid;
                const fromTimestamp = reservation.fromTimestamp;
                const firstDay = Math.max(Math.floor((fromTimestamp - this.minReservationDate.getTime()) / DAY_MILLISECONDS), 0);
                const numDays = this.getReservationDateSpan(reservation);
                const daysAfter = Math.max(0, this.reservationDaySpan - firstDay - numDays);
                const accepted = reservation.accepted;
                const spot = (_f = reservation.spot) !== null && _f !== void 0 ? _f : 0;
                const notes = reservation.notes;
                const arrived = reservation.arrived;
                let message = `Plaats ${spot} van ${convertTimestamp(reservation.fromTimestamp, false, false)} `;
                message += `tot ${convertTimestamp(reservation.toTimestamp, false, false)}`;
                return { id, customerid, customername, numDays, firstDay, daysAfter, message, accepted, notes, licenseplate,
                    arrived, fromTimestamp };
            }).sort(function (a, b) { return a.fromTimestamp - b.fromTimestamp; });
        }
    },
    methods: {
        getDateAfterMinDate(days) {
            return convertTimestamp(this.getTimeAfterMinDate(days), false, false);
        },
        getTimeAfterMinDate(days) {
            return this.minReservationDate.getTime() + days * DAY_MILLISECONDS;
        },
        getReservationDateSpan(item) {
            const datespan = midnightOfDate(item.toDate).getTime() - midnightOfDate(item.fromDate).getTime();
            return Math.max(Math.round(datespan / (DAY_MILLISECONDS)), 1);
        },
        csvDownload() {
            const lines = [];
            const line = 'Locatie;Klantnaam;Email;GSM;Van;Tot;Plaats;Aantal personen;Nummerplaat;Bevestigd;Commentaar';
            lines.push(line);
            Object.values(this.reservations).forEach(reservation => {
                const customer = this.customers[reservation.customerid];
                const line = reservation.location + ';'
                    + getCustomerCSV(customer) + ';'
                    + convertTimestamp(reservation.fromTimestamp) + ';'
                    + convertTimestamp(reservation.toTimestamp) + ';'
                    + reservation.spot + ';'
                    + reservation.numberPersons + ';'
                    + reservation.licenseplate + ';'
                    + reservation.accepted + ';'
                    + reservation.comment + ';';
                lines.push(line);
            });
            generateCsvDownload(lines, 'reservations');
        }
    }
});
