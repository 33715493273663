import { useToast } from 'vue-toastification';
const usePromiseStatus = () => {
    const toast = useToast();
    const showStatus = async ({ action, successMessage, errorMessage, confirmationMessage }) => {
        if (confirmationMessage !== undefined) {
            const sure = confirm(confirmationMessage);
            if (!sure)
                return;
        }
        try {
            await action();
            toast.success(successMessage);
        }
        catch (error) {
            toast.error(`${errorMessage}: ${error}`);
        }
    };
    return {
        showStatus
    };
};
export default usePromiseStatus;
