const generateCsvDownload = (lines, filename) => {
    const preamble = 'data:application/octetstream;base64,';
    const csvContent = lines
        .join('\n')
        .replace(/(^\[)|(]$)/gm, '');
    const data = preamble + btoa(unescape(encodeURIComponent(csvContent)));
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename + '.csv');
    link.click();
};
const maybe_csv_escape = (s) => {
    const t = s.replace(/"/g, '""'); // repeat double quotes
    if (t.includes(';')) {
        return '"' + t + '"'; // wrap
    }
    return t;
};
const getCustomerCSV = (customer) => {
    if (customer === undefined)
        return '';
    else
        return customer.firstname + ' '
            + customer.lastname + ','
            + customer.email + ','
            + customer.phone;
};
export { generateCsvDownload, maybe_csv_escape, getCustomerCSV };
