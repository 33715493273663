/* eslint-disable max-lines */
import ReservationForm from '@/components/Reservation/ReservationForm';
import CalculateReservation from '@/components/Reservation/CalculateReservation';
import ModalBox from '@/components/ModalBox';
import TakeAwayOverviewTable from '@/components/TakeAway/TakeAwayOverviewTable';
import { defineComponent } from 'vue';
import useReservations from '@/compositions/reservations';
import useCustomers from '@/compositions/customers';
import usePromiseStatus from '@/compositions/promiseStatus';
import { cloneDeep } from 'lodash';
import { convertTimestamp } from '@/classes/timestamps';
export default defineComponent({
    name: 'EditReservationButton',
    components: {
        ReservationForm,
        ModalBox,
        TakeAwayOverviewTable,
        CalculateReservation
    },
    props: {
        reservationId: {
            type: String,
            required: true
        },
        showAsLink: {
            type: Boolean,
            required: false,
            default: false
        },
        text: {
            type: String,
            required: false,
            default: 'Pas aan'
        },
        onlyReservation: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup: () => ({
        ...useReservations(),
        ...useCustomers(),
        ...usePromiseStatus()
    }),
    data: () => ({
        showModal: false,
        isValid: false,
        reservation: undefined,
        processing: false,
        fromDate: new Date(),
        toDate: new Date(),
        takeawayFilter: {
            field: 'uid',
            value: '',
            fromDate: null,
            toDate: null
        }
    }),
    computed: {
        storeReservation() {
            return this.reservations[this.reservationId];
        },
        accepted() {
            var _a, _b;
            if (this.reservationId === null)
                return false;
            return (_b = (_a = this.reservations[this.reservationId]) === null || _a === void 0 ? void 0 : _a.accepted) !== null && _b !== void 0 ? _b : false;
        },
        isSuperUser() {
            return this.$store.direct.getters.auth.isSuperUser;
        }
    },
    watch: {
        storeReservation: {
            handler() {
                this.reservation = cloneDeep(this.storeReservation);
            },
            immediate: true
        }
    },
    methods: {
        openDialog() {
            this.updateFilter();
            this.showModal = true;
        },
        updateFilter() {
            this.reservation = this.reservations[this.reservationId];
            if (this.reservation !== undefined) {
                this.fromDate = this.reservation.fromDate;
                this.toDate = this.reservation.toDate;
                this.takeawayFilter.field = 'uid';
                this.takeawayFilter.value = this.reservation.customerid;
                this.takeawayFilter.fromDate = this.fromDate;
                this.takeawayFilter.toDate = this.toDate;
                this.$store.direct.dispatch.takeaway.applyFilter(this.takeawayFilter);
            }
        },
        async updateReservation() {
            const reservation = this.reservation;
            if (reservation === undefined)
                return;
            this.showStatus({
                action: async () => {
                    await this.$store.direct.dispatch.reservations.update({
                        id: this.reservationId,
                        updates: {
                            notes: reservation.notes,
                            comment: reservation.comment,
                            licenseplate: reservation.licenseplate,
                            numberPersons: reservation.numberPersons,
                            customerid: reservation.customerid,
                            arrived: reservation.arrived
                        }
                    });
                },
                successMessage: 'Reservatie aangepast',
                errorMessage: 'Aanpassen reservatie mislukt'
            });
            this.showModal = false;
        },
        updateValidity(valid) {
            this.isValid = (valid || this.isSuperUser);
        },
        async acceptReservation() {
            await this.showStatus({
                action: async () => {
                    if (this.reservationId === null)
                        throw new Error('Cannot accept reservation with id == null');
                    await this.$store.direct.dispatch.reservations.acceptReservation(this.reservationId);
                    this.sendAcceptMail();
                    this.showModal = false;
                },
                successMessage: 'Reservatie geaccepteerd',
                errorMessage: 'Could not accept reservation'
            });
        },
        async cancelReservation() {
            this.showStatus({
                action: async () => {
                    if (this.reservationId === null)
                        throw new Error('Cannot cancel reservation with id == null');
                    await this.$store.direct.dispatch.reservations.cancelReservation(this.reservationId);
                },
                successMessage: 'Reservatie geannuleerd',
                errorMessage: 'Could not cancel reservation',
                confirmationMessage: 'Ben je zeker dat je deze reservatie wilt annuleren inclusief takeaways?'
            });
        },
        sendAcceptMail() {
            const reservation = this.reservations[this.reservationId];
            if (reservation === undefined)
                return;
            const customer = this.customers[reservation.customerid];
            if (customer === undefined || !customer.email)
                return;
            const mail = {
                destination: [customer.email],
                subject: 'Bevestiging van je reservatie bij Kokima camperlocatie',
                body: `Dag ${customer === null || customer === void 0 ? void 0 : customer.firstname},\r\nBedankt voor jullie reservatie van` +
                    `${convertTimestamp(reservation.fromDate.getTime())} tot ${convertTimestamp(reservation.toDate.getTime())}`
            };
            this.$store.direct.dispatch.mail.sendMail(mail);
        }
    }
});
