import { defineComponent } from 'vue';
import Customers from '@/components/Customers';
import CustomerEditButton from '@/components/Customers/CustomerEditButton';
import useAuth from '@/compositions/auth';
import { Customer } from '@/types/customers';
export default defineComponent({
    name: 'CustomersOverview',
    components: {
        Customers,
        CustomerEditButton
    },
    setup() {
        return { ...useAuth() };
    },
    computed: {
        getCustomer() {
            return new Customer();
        }
    }
});
