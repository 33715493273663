import { defineComponent } from 'vue';
import ModalBox from '@/components/ModalBox';
import TakeAwayForm from '@/components/TakeAway/TakeAwayForm';
import useProducts from '@/compositions/products';
import useCustomers from '@/compositions/customers';
import useTakeAway from '@/compositions/takeaway';
import useReservations from '@/compositions/reservations';
import MakeTakeAwayButton from '@/components/TakeAway/MakeTakeAwayButton';
import { useToast } from 'vue-toastification';
import { cloneDeep } from 'lodash';
import { convertTimestamp } from '@/classes/timestamps';
import { generateCsvDownload, getCustomerCSV } from '@/classes/csv';
export default defineComponent({
    name: 'TakeAwayOverviewTable',
    components: {
        ModalBox,
        TakeAwayForm,
        MakeTakeAwayButton
    },
    props: {
        tableOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        createNewReservationId: {
            type: String,
            required: false,
            default: null
        }
    },
    setup() {
        return {
            ...useProducts(),
            ...useCustomers(),
            ...useReservations(),
            ...useTakeAway(),
            toast: useToast()
        };
    },
    data: () => ({
        showModal: null,
        editTakeAway: null,
        showKitchenName: false,
        printDate: new Date(0, 0, 0)
    }),
    methods: {
        async deleteTakeAway(id) {
            const sure = confirm('Ben je zeker dat je deze Take-away wilt verwijderen?');
            if (!sure)
                return;
            try {
                await this.$store.direct.dispatch.takeaway.delete(id);
                this.toast.success('Takeaway deleted');
            }
            catch (error) {
                this.toast.error(`Could not delete takeaway ${error}`);
            }
        },
        printPickUpDay(d) {
            return ('0' + d.getDate()).slice(-2) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        },
        printPickUpHour(d) {
            return ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2);
        },
        openModal(takeawayId) {
            const ta = this.takeaways[takeawayId];
            if (ta === undefined) {
                console.error('Could not find takeaway');
                return;
            }
            this.editTakeAway = cloneDeep(ta);
            this.showModal = takeawayId;
        },
        async updateTakeAway() {
            if (this.showModal === null)
                return;
            if (this.editTakeAway === null) {
                console.error('updateTakeAway error, editTakeAway is undefined');
                return;
            }
            const data = this.editTakeAway.dump();
            try {
                await this.$store.direct.dispatch.takeaway.update({
                    id: this.showModal,
                    update: {
                        products: data.products,
                        pickUpDate: data.pickUpDate,
                        comment: data.comment
                    }
                });
                this.toast.success('Takeaway aangepast');
            }
            catch (error) {
                this.toast.error(`Fout bij het aanpassen van de takeaway ${error}`);
            }
            this.showModal = null;
        },
        newDate(date) {
            if (date.getDate() === this.printDate.getDate() &&
                date.getMonth() === this.printDate.getMonth())
                return false;
            else {
                this.printDate.setTime(date.getTime());
                return true;
            }
        },
        csvDownload() {
            const lines = [];
            const line = 'Afhaalmoment;Klantnaam;Email;GSM;Gerechten';
            lines.push(line);
            Object.values(this.takeaways).forEach(takeaway => {
                const customer = this.customers[takeaway.customerId];
                let line = convertTimestamp(takeaway.pickUpDate.getTime()) + ';'
                    + getCustomerCSV(customer) + ';';
                takeaway.products.forEach(product => {
                    line += product.name + ';€ ' + product.price + ';';
                });
                lines.push(line);
            });
            generateCsvDownload(lines, 'takeaway');
        }
    }
});
