import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import useTakeAway from '@/compositions/takeaway';
import useReservations from '@/compositions/reservations';
export default defineComponent({
    name: 'ReservationFilter',
    setup() {
        return { ...useTakeAway(true), ...useReservations(true) };
    },
    data: () => ({
        localFilter: {
            field: null,
            value: '',
            fromDate: null,
            toDate: null
        }
    }),
    computed: {
        takeawayFilter() {
            return this.$store.direct.state.takeaway.filter;
        }
    },
    watch: {
        takeawayFilter: {
            handler(filter) {
                this.localFilter = cloneDeep(filter);
            },
            immediate: true
        }
    },
    methods: {
        updateFilter() {
            this.$store.direct.dispatch.takeaway.applyFilter(this.localFilter);
            const reservationFilter = {
                field: this.localFilter.field,
                value: this.localFilter.value,
                fromDate: null,
                toDate: null,
                sorted: 'fromDate'
            };
            this.$store.direct.dispatch.reservations.applyFilter(reservationFilter);
        }
    }
});
