import { defineComponent } from 'vue';
import CustomerDetail from '@/components/Customers/CustomerDetail';
export default defineComponent({
    name: 'CustomerDetailModal',
    components: {
        CustomerDetail
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        customerid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        showCustomerDetailModal: false
    })
});
