/* eslint-disable max-lines */
import MakeReservationForm from '@/components/Reservation/MakeReservationForm';
import ModalBox from '@/components/ModalBox';
import useAuth from '@/compositions/auth';
import useLocations from '@/compositions/locations';
import { useToast } from 'vue-toastification';
import { defineComponent } from 'vue';
import useCustomers from '@/compositions/customers';
export default defineComponent({
    name: 'MakeReservationButton',
    components: {
        MakeReservationForm,
        ModalBox
    },
    props: {
        buttonType: {
            type: String,
            required: false,
            validator: (value) => ['text', 'image'].includes(value),
            default: 'image'
        },
        showAsLink: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
        return { ...useAuth(), ...useLocations(), ...useCustomers(), toast: useToast() };
    },
    data: () => ({
        showModal: false,
        isValid: false,
        processing: false,
        key: 1
    }),
    computed: {
        image() {
            return require('@/assets/newreservation.svg');
        }
    },
    methods: {
        openDialog() {
            var _a, _b;
            this.showModal = true;
            (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.scrollIntoView(true);
            (_b = document.querySelector('.reservation-wrapper .top')) === null || _b === void 0 ? void 0 : _b.scrollIntoView(true);
        }
    }
});
