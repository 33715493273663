import useCustomers from '@/compositions/customers';
import CustomerEditButton from '@/components/Customers/CustomerEditButton';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Customers',
    components: {
        CustomerEditButton
    },
    setup() {
        return { ...useCustomers() };
    }
});
