import useCustomers from '@/compositions/customers';
import { Customer } from '@/types/customers';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CustomerDetails',
    props: {
        customerid: {
            type: String,
            required: true
        }
    },
    setup() {
        return { ...useCustomers() };
    },
    computed: {
        customer() {
            const customer = this.customers[this.customerid];
            return customer !== undefined ? customer : new Customer();
        }
    }
});
