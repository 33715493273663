import { defineComponent } from 'vue';
import CustomerFilter from '@/components/Customers/CustomerFilter';
import useTakeAway from '@/compositions/takeaway';
import useReservations from '@/compositions/reservations';
import ReservationOverviewTable from '@/components/Reservation/ReservationOverviewTable';
import TakeAwayOverviewTable from '@/components/TakeAway/TakeAwayOverviewTable';
import useAuth from '@/compositions/auth';
export default defineComponent({
    name: 'Customer',
    components: {
        CustomerFilter,
        ReservationOverviewTable,
        TakeAwayOverviewTable
    },
    setup() {
        return { ...useTakeAway(false), ...useReservations(false), ...useAuth() };
    }
});
