import TakeAwayForm from '@/components/TakeAway/TakeAwayForm';
import ModalBox from '@/components/ModalBox';
import useAuth from '@/compositions/auth';
import useLocations from '@/compositions/locations';
import { useToast } from 'vue-toastification';
import { defineComponent } from 'vue';
import { midnightOfDate } from '@/classes/timestamps';
import { TakeAway } from '@/types/takeaway';
import useReservations from '@/compositions/reservations';
export default defineComponent({
    name: 'MakeTakeAwayButton',
    components: {
        ModalBox,
        TakeAwayForm
    },
    props: {
        reservationId: {
            type: String,
            required: false,
            default: null
        },
        buttonType: {
            type: String,
            required: true,
            validator: (value) => ['text', 'image'].includes(value)
        },
        showAsLink: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
        return { ...useAuth(), ...useLocations(), ...useReservations(), toast: useToast() };
    },
    data: () => ({
        showModal: false,
        isValid: false,
        processing: false,
        takeaway: null
    }),
    computed: {
        attachedReservationId() {
            if (this.reservationId !== null)
                return this.reservationId;
            return this.$store.direct.getters.reservations.mostRecentReservationId;
        },
        reservation() {
            if (this.reservationId === null)
                return this.$store.direct.getters.reservations.mostRecentReservation;
            else
                return this.reservations[this.reservationId];
        },
        image() {
            return require('@/assets/takeaway.svg');
        }
    },
    methods: {
        createDefaultTakeAway() {
            if (this.reservation === undefined)
                return;
            if (this.uid !== undefined && this.currentLocation !== undefined && this.attachedReservationId !== undefined) {
                const fromDate = new Date(midnightOfDate(this.reservation.fromDate).getTime() + 12 * 3600 * 1000);
                this.takeaway = new TakeAway(this.attachedReservationId, this.reservation.customerid, fromDate, this.currentLocationId);
            }
            else {
                console.error('MakeReservationButton: could not make new reservation', this.uid, this.currentLocation);
            }
        },
        openDialog() {
            this.createDefaultTakeAway();
            this.showModal = true;
        },
        async makeTakeAway() {
            if (this.takeaway === null)
                return;
            if (!this.isValid)
                return;
            this.processing = true;
            try {
                this.$store.direct.dispatch.takeaway.add(this.takeaway);
                this.toast.success('Uw takeaway aanvraag is succesvol ontvangen');
            }
            catch (error) {
                this.toast.error(`Er is een fout opgetreden tijdens de aanvraag van de takeaway ${error}`);
            }
            this.showModal = false;
            this.processing = false;
        }
    }
});
